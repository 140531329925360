/* styles.css */
.zoom-container {
    overflow: hidden;
    position: relative;
  }
  
  .zoom-image {
    transition: transform 0.3s; /* Add a smooth transition effect */
  }
  
  .zoom-container:hover .zoom-image {
    transform: scale(1.2); /* Increase the scale on hover */
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 20px;
    text-align: center;
  }
  
  .text-overlay h1 {
    margin: 0;
  }
  .image-container {
    position: relative;
    text-align: center;
  }
  