@font-face {
  font-family: 'AdorHairline-Bold';
  src: url('../fonts/AdorHairline-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AdorHairline-ExtraBold';
  src: url('../fonts/AdorHairline-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'Neue-Haas';
    src: url('../fonts/Neue-Haas-Grotesk-Text-W02-Regular.ttf') format('truetype');

  }
  @font-face {
    font-family: 'Neue-Haas-regular';
    src: url('../fonts/Neue Haas Grotesk Text W02 Regular.ttf') format('truetype');

  }
  @font-face {
    font-family: 'AdorHairline-Light';
    src: url('../fonts/AdorHairline-Light.ttf') format('truetype');

  }
  * {
    margin: 0;
    padding: 0;
  }
 